@media only screen and (max-width: '768px') {
  code {
    font-size: 12px;
  }
}

.test__question-title__link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: normal;

  svg {
    margin-right: 10px;
  }

  a {
    font-size: 20px;
    color: #EF3124;
    line-height: 1;

    @media only screen and (max-width: '768px') {
      font-size: 14px;
    }
  }
}

.test__btn-block_2nd {
  display: flex;
  justify-content: center;

  .test__btn-next:first-child {
    margin-right: 20px;
  }

  .test__btn-next {
    width: 115px;
    height: 30px;
    color: #fff;
    background-color: #EF3124;
    border: 0;
    outline: none;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

// @font-face {
//   font-family: 'Frederik';
//   src: url('./Frederik-Medium.ttf');
//   font-weight: normal;
//   font-style: normal;
// }

body {
  margin: 0;
  font-family: 'Roboto', 'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

a:visited {
  text-decoration: none
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #EF3124;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #EF3124;
}
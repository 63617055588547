.confirm-page {
  button {
    width: 100px;
  }

  .btn-yes {
    margin-left: 50px;
  }

  .test-test-test-test {
    display: flex;
    justify-content: center;
    align-self: center;
  }
}
.radio-group-wrapper {
  margin-bottom: 30px;

  .radio-group__label {
    position: relative;
    display: table;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 30px;
    font-size: 14px;
    line-height: 1.25;
    color: #000;
    word-break: break-word;
    cursor: pointer;
    box-sizing: content-box;
  }

  .radio-group__radio {
    position: absolute;
    z-index: -1;
    opacity: 0;
    box-sizing: content-box;

    &.checked {
      +.radio-group__radio-indicator {
        opacity: 1;
      }

      +.radio-group__radio-indicator::after {
        opacity: 1;
      }
    }

    &-indicator {
      position: absolute;
      top: -1px;
      left: 0;
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border-color: #EF3124;
      border-style: solid;
      border-width: 2px;
      box-sizing: border-box;
      border-radius: 50%;
      opacity: 0.6;
      transition: all 0.2s;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 10px;
        height: 10px;
        margin: auto;
        background: #EF3124;
        border-radius: 50%;
        opacity: 0;
        transition: all 0.2s;
      }
    }
  }
}

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 850px;
  margin: 80px auto 50px;
  padding: 0;
  box-sizing: border-box;

  h3 {
    color: #000;
    margin: 0 0 10px;
    @media only screen and (max-width: '768px') {
      font-size: 14px;
    }
  }

  img {
    max-width: 150px;
  }

  .t-sociallinks__item {
    margin: 4px 1px 7px;
    overflow: hidden;

    a {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }
  }

  a {
    color: #000;
    font-size: 18.72px;
    font-weight: bold;
    line-height: 1.5;

    @media only screen and (max-width: '768px') {
      font-size: 14px;
    }
  }
}

.table-question {
  border-collapse: collapse;

  &-wrapper {
    overflow-x: auto;
  }

  thead {
    td {
      padding-left: 120px;
    }

    th {
      padding: 10px 15px;
    }
  }

  tbody {
    .radio-group-wrapper {
      display: table-row;
    }

    .radio-group__radio-indicator {
      top: -9px;
      left: -10px;
    }

    td:first-child {
      padding-top: 12px;
      padding-right: 5px;
      padding-bottom: 12px;
      padding-left: 5px;
      text-align: left;
    }
  }
}

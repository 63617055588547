.radio-group-wrapper {
  margin-bottom: 30px;

  .radio-group__label {
    position: relative;
    display: table;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 24px !important;
    font-size: 14px !important;
    line-height: 1.25 !important;
    color: #000;
    word-break: break-word;
    cursor: pointer;
    box-sizing: content-box;
  }

  .radio-group__radio {
    position: absolute;
    z-index: -1;
    opacity: 0;
    box-sizing: content-box;

    &.checked {
      +.radio-group__radio-indicator {
        opacity: 1;
      }

      +.radio-group__radio-indicator::after {
        opacity: 1;
      }
    }

    &-indicator {
      position: absolute;
      top: calc(50% - 2px) !important;
      transform: translateY(-50%) !important;
      left: 0;
      display: inline-block;
      vertical-align: middle;
      width: 12px !important;
      height: 12px !important;
      margin-right: 11px;
      border-color: #e2e2e2 !important;
      border-style: solid;
      border-width: 1px !important;
      box-sizing: border-box;
      border-radius: 50%;
      transition: all 0.2s;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 6px !important;
        height: 6px !important;
        margin: auto;
        background: #EF3124;
        border-radius: 50%;
        opacity: 0;
        transition: all 0.2s;
      }
    }
  }
}

/* stylelint-disable */
.attachments-block__upload-field {
  width: 100%;
  margin-top: 25px;

  .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
    background-color: transparent;
    border: 1px dashed #EF3124;

    &__aside {
      margin-top: 25px;
      font-size: 1.1rem;
      line-height: 1.1;

      ul {
        position: relative;
        display: flex;
        align-items: center;
        margin: 0;
        padding-left: 0;
        word-break: break-all;
        list-style: none;

        p {
          margin: 0;
        }

        li {
          margin-top: 4px;
          font-size: 1.2rem;
          line-height: 1.1;
          color: #000;
        }

        button {
          position: relative;
          min-width: 105px;
          margin-left: 10px;
        }
      }

      + .button-checked {
        width: 200px;
        margin-top: 25px;
      }
    }

    p {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 1rem;
      line-height: 1.1;
      color: #000;
    }
  }
}

.autocomplete-input {
  div {
    position: relative;
    width: 100%;
  }

  input {
    position: relative !important;
    z-index: 999999999 !important;
    width: 100% !important;
    height: 50px !important;
    margin: 0 !important;
    padding: 10px 20px !important;
    font-size: 14px;
    line-height: 32px !important;
    color: #000 !important;
    background-color: #fff !important;
    border: 1px solid #E2E2E2 !important;
    border-bottom: 3px solid #E2E2E2 !important;
    border-radius: 0 !important;
    appearance: none !important;
    outline: none !important;
    transition: all ease 0.3s !important;
    box-sizing: border-box !important;

    &:focus {
      border-bottom: 3px solid #EF3124 !important;
    }

    + div {
      top: 30px !important;
      background-color: #e2e2e2 !important;
      font-family: 'Roboto';
      box-sizing: border-box;

      > div {
        padding-left: 20px !important;
        box-sizing: border-box;
        font-size: 16px !important;
        line-height: 40px !important;
        transition: all ease 0.3s;
        cursor: pointer;

        &:first-child {
          margin-top: 20px !important;
        }

        &:hover {
          background-color: #EF3124 !important;
          color: #fff !important;
        }
      }
    }
  }
}

.progress-bar {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 850px;
  height: 60px;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;

  &__title {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 18px;
    font-weight: 700;
    color: #c7c7c7;

    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  &__step {
    color: #000;
  }

  &__body {
    position: relative;
    width: 100%;
    height: 10px;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../../img/chest.svg');
      background-size: 100%;
      background-position: 0 -6px;
    }
  }

  &__item {
    position: relative;
    height: 10px;
    background-color: #EF3124;
    border-right: 0;
    border-left: 0;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    &:first-child {
      border-left: 1px solid #000;
    }

    &:last-child {
      border-right: 1px solid #000;
    }
  }

  // &__item_1 {
  //   position: absolute;
  //   height: 7px;
  //   width: 7px;
  //   box-sizing: border-box;
  //   border: 5px solid transparent;
  //   border-left: 5px solid #EF3124;

  //   &:not(:last-child) {
  //     &::after {
  //       content: '';
  //       position: absolute;
  //       z-index: 9999;
  //       top: 50%;
  //       right: 3px;
  //       transform: translateY(-50%);
  //       width: 8px;
  //       height: 8px;
  //       border-radius: 50%;
  //       background-color: #fff;
  //     }
  //   }
  // }
}
.captcha {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: -15px;

  input {
    border-width: 1px;
    outline: none;
  }

  &_disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &_disabled_first {
    .button-default_blue {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .button-default_blue {
    margin-right: 10px;
  }

  input {
    font-size: 16px;
    width: 144px;
    height: 36px;
    margin-left: 8px;
  }
}
